@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: 'Inter', sans-serif;
}

.animation {
  transition: all 300ms;
}

.content-container {
  height: calc(100vh - theme('spacing.40'));
}

.user-access-auth-stack-content-container {
  height: calc(100vh - theme('spacing.96'));
}

.user-access-content-container {
  height: calc(100vh - theme('spacing.80') - theme('spacing.4'));
}

.manage-documents-content-container {
  height: calc(100vh - theme('spacing.80') - theme('spacing.10'));
}

.manage-db-content-container {
  height: calc(100vh - theme('spacing.80') - theme('spacing.20'));
}

.dashboard-queries-content-container {
  height: calc(100vh - theme('spacing.96') - theme('spacing.10'));
}

.emails-content-container {
  height: calc(100vh - theme('spacing.60') - theme('spacing.8'));
}

.manage-doc-content-container {
  height: calc(100vh - theme('spacing.56') - theme('spacing.3'));
}

.manage-database-content-container {
  height: calc(100vh - theme('spacing.60') - theme('spacing.8'));
} 

.email-manager-content-container {
  height: calc(100vh - theme('spacing.56') - theme('spacing.3'));
}

.progress-steper-content-container {
  height: calc(100vh - theme('spacing.60') - theme('spacing.6'));
}

.progress-steper-content-container-without-bottom-bar {
  height: calc(100vh - theme('spacing.40'));
}

.integrations-content-container {
  height: calc(100vh - theme('spacing.52'));
}

.workflow-container {
  width: calc(100vw - theme('spacing.96') - theme('spacing.4'));
}

.workflow-with-side-panel-container {
  width: calc(100vw - theme('spacing.80') - theme('spacing.32') - theme('spacing.2'));
}

.workflow-with-side-bar-container {
  width: calc(100vw - theme('spacing.96') - theme('spacing.72') - theme('spacing.4'));
}

.workflow-with-side-bar-and-side-panel-container {
  width: calc(100vw - theme('spacing.80') - theme('spacing.80') - theme('spacing.28'));
}

.chat-container {
  height: calc(100vh - 2*theme('spacing.10'));
}

.content-container-with-top-bar {
  height: calc(100vh - theme('spacing.28'));
}

.chat-content-container {
  height: calc(100vh - theme('spacing.52'));
}

.co-pilot-highlights-content-container {
  max-height: theme('spacing.48');
}

.co-pilot-summary-content-container {
  max-height: theme('spacing.64');
}

.co-pilot-suggested-reply-content-container {
  max-height: theme('spacing.64');
}

.db-search-result-content-container {
  max-height: calc(100vh - theme('spacing.72'));
}

.side-bar-content-container {
  height: calc(100vh - theme('spacing.36'));
}

.db-search-side-panel-source-container {
  height: calc(100vh - theme('spacing.72') - theme('spacing.7'));
}

.doc-search-side-panel-container {
  height: calc(100vh - theme('spacing.52'));
}

.doc-search-side-panel-source-container {
  height: calc(100vh - theme('spacing.96') - theme('spacing.8'));
}

.add-llm-container {
  height: calc(100vh - theme('spacing.52'));
}

.input-container {
  position: relative;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 60%;
  transform: translateY(-67.5%);
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.focused label {
  transform: translateY(-155%) scale(0.8);
  padding-left: 2px;
  padding-right: 2px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar-track {
  background: #0001;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #336fe4;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

::-webkit-scrollbar:hover {
  opacity: 1;
}

.file-based-file-name {
  width: calc(100% - theme('spacing.14'));
}

.popup-ease-in {
  animation-name: ease-in;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
}

.popup-ease-out {
  animation-name: ease-out;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
}

@keyframes ease-in {
  0% {
    transform: translate3d(0px, 700px, 0px);
    opacity: 0.5;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@keyframes ease-out {
  0% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate3d(0px, 700px, 0px);
    opacity: 0.5;
  }
}

.popup-ease-in-opacity {
  animation-name: ease-in-opacity;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-opacity;
}

.popup-ease-out-opacity {
  animation-name: ease-out-opacity;
  animation-duration: 0.6s;
  animation-timing-function: ease-out-opacity;
}

@keyframes ease-in-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes ease-out-opacity {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.toast-ease-in {
  animation-name: ease-in;
  animation-duration: 0.6s;
  animation-timing-function: toast-ease-in;
}

.toast-ease-out {
  animation-name: ease-out;
  animation-duration: 0.6s;
  animation-timing-function: toast-ease-out;
}

@keyframes toast-ease-in {
  0% {
    transform: translate3d(0px, 200px, 0px);
    opacity: 0.5;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@keyframes toast-ease-out {
  0% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }

  100% {
    transform: translate3d(0px, 200px, 0px);
    opacity: 0.5;
  }
}