.chatHTML div {
	font-size: 1rem;
	font-weight: 300;
}

.chatHTML h1 {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
	margin-top: 1.2rem;
}

.chatHTML h2 {
	font-size: 1.35rem;
	font-weight: 600;
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}

.chatHTML h3 {
	font-size: 1.25rem;
	font-weight: 600;
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}

.chatHTML h4 {
	font-size: 1.2rem;
	font-weight: 500;
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}

.chatHTML h5 {
	font-size: 1.15rem;
	font-weight: 500;
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}

.chatHTML h6 {
	font-size: 1.10rem;
	font-weight: 500;
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}

.chatHTML br {
	line-height: 0rem;
	font-size: 0rem;
	height: 0rem;
}

.chatHTML p {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.chatHTML h1,
h2,
h3,
h4,
h5,
h6 b {
	font-weight: 600;
}

.chatHTML b {
	font-weight: 600;

}

.chatHTML ul {
	list-style: disc;
	margin-left: 0.75rem;
}

.chatHTML li {
	font-size: 1rem;
	font-weight: 300;
	margin-top: 0.7rem;
	margin-bottom: 0rem;
}

.chatHTML .custom_list{
	margin-bottom: 0.5rem;
}
.chatHTML .custom_list ul {
	list-style: none;
}

.chatHTML .custom_list li {
	list-style: none;
}

.chatHTML table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
	margin-right: 8px;
}

.chatHTML th,
.chatHTML td {
	border: 1px solid #576474;
	padding: 8px;
	text-align: left;
}

.chatHTML strong{
	font-weight: 600;
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	60% {
		transform: translateY(-5px);
	}
}

.dot {
	width: 10px;
	height: 10px;
	margin: 5px 5px;
	/* background-color: #fff; */
	border-radius: 50%;
	display: inline-block;
	animation: bounce 1.5s infinite ease-in-out;
}

.bounce1 {
	animation-delay: -0.32s;
}

.bounce2 {
	animation-delay: -0.16s;
}

.bounce3 {
	animation-delay: 0s;
}